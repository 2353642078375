.Wizard{
    flex-direction: column;
}

.HeaderWizard{
    display: flex;
    overflow-x: auto;
    width: 100%;
    justify-content: center;
    padding-bottom: 2rem;
    border-bottom: 1px solid #dee2e6;
    position: relative;
}

@media (min-width: 992px) {
    .HeaderWizard {
        padding-bottom: 3.5rem;
    }
}

.HeaderWizard-item{
    display: flex;
}


.HeaderWizard-item .HeaderWizard-item-name{
    display: none;
}

@media (min-width: 992px) {

    .HeaderWizard-item .HeaderWizard-item-name{
        display: block;
    }
}

.HeaderWizard-item-number{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    font-size: 1.1rem;

    background-color: #ddd;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .HeaderWizard-item-number {
        width: 45px;
        height: 45px;
        border-radius: 45px;
        font-size: 1.3rem;
    }
}

.HeaderWizard .HeaderWizard-item:last-child .HeaderWizard-item-line{
    display: none;
}

.HeaderWizard-item-line{
    width: 4rem;
    height: 4px;
    background-color: #e4e4e4;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: 10px;
    transition: .5s all ease;
}

@media (min-width: 992px) {
    .HeaderWizard-item-line {
        width: 10rem;
        height: 7px;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }    
}

.HeaderWizard-item-info{
    position: static;
    cursor: pointer;
    transition: .5s all ease;
}

@media (min-width: 992px) {

    .HeaderWizard-item-info{
        position: relative;
    }
}

.HeaderWizard-item-name{
    width: auto;
    left: 0;
    right: 0;
    bottom: 10px;
    position: absolute;
    text-align: center;
    color: #b0b0b0;
    transition: .5s all ease;
}

@media (min-width: 992px) {
    .HeaderWizard-item-name{
        width: 200px;
        left: -76px;
        bottom: -35px;
        font-size: 1rem;
    }
}

.HeaderWizard-item_focus .HeaderWizard-item-number{
    background-color: #fff;
    border: 1px solid var(--bg-primary);
    color: var(--bg-primary);
    transition: .5s all ease;
}

.HeaderWizard-item_focus .HeaderWizard-item-name{
    color: var(--bg-primary);
}

.HeaderWizard-item_complete .HeaderWizard-item-number{
    background-color: var(--bg-primary);
    color: #fff;
}

.HeaderWizard-item_complete .HeaderWizard-item-name{
    color: var(--bg-primary);
}

.HeaderWizard-item_complete .HeaderWizard-item-line{
    background-color: var(--bg-primary);
}


.BodyWizard{
    background-color: #FAFAFA;
}

@-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-1rem, 0, 0);
      transform: translate3d(-1rem, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-1rem, 0, 0);
        transform: translate3d(-1rem, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.Wizard .animate__fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

/* Reajuste para varios elementos */

.HeaderWizard--shrink .HeaderWizard-item-line {
    width: 7rem;
}