.MainInner-wizard{
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

@media (max-width: 768px){
    .MainInner-wizard{
        /* padding: 1rem !important; */
    }
}

.MainInner-wizard .HeaderWizard{
    padding-left: 1.2rem;
    padding-top: 1.2rem;
    padding-right: 1.2rem;
}

.MainInner-wizard .HeaderWizard-item-line{
    width: 1rem;
}

/* @media (min-width: 768px) {
    .MainInner-wizard .HeaderWizard-item-line {
        width: 1rem;
    }    
} */

@media (min-width: 992px) {
    .MainInner-wizard .HeaderWizard-item-line {
        width: 8.5rem;;
    }    
}

.MainInner-wizard .BodyWizard{
    padding: 1.2rem;
}

.input-color-paleta{
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
}

#ModalCopiarDatos{
    z-index: 11111;
}

.muestra_pendiente{
    background-color: #fff0f0;
}

.tornar-facturacio{
    position: relative;
}

@media (min-width: 992px){
    .tornar-facturacio{
        position: absolute;
    }
    
}