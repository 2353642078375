/*Fix para el home*/
.small{
	font-size: 15px !important;
	color: #215F9A !important;
}

.section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.section-border {
    flex-grow: 1;
}

.text-radon{
	color: #7CA3D1 !important;
}

.section-border-1 {
    box-shadow: 1 2px 5px 0 rgba(0, 0, 0, 0.16) !important;
    border: 1px solid #7CA3D1 !important;
}

.home-list .list-group{
	border: 1px solid #7CA3D1 !important;
	border-radius: none !important;
}

.home-list .list-group .list-group-item {
    border-radius: 0 !important;
}

.home-list .list-group-item{
	background-color: transparent !important;
}

.btn{
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
}

.btn-primary:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.table-bordered {
    border: 1px solid #fff !important; 
}

.table-bordered th {
    color: #fff !important;
    border: 1px solid #eee !important; 
    background-color: #7CA3D1 !important; 
}

.table-bordered td {
    border: 1px solid #eee !important; 
}
