.ListFiles li{
    cursor: auto !important;
}

.ListFilesInline{
    flex-direction: row !important;
}

.ListFilesInline li{
    border: 1px solid var(--bs-list-group-border-color) !important;
    border-radius: 5px;
    margin: 5px !important;
}