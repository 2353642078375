.DropdownLanguage svg{
    font-size: 1.15rem !important;
}

.DropdownLanguage button{
    color: #333 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 0 !important;
    border: 0 !important;
}